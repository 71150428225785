import React from "react"
import { Link } from "gatsby"
import { Title } from "bloomer"

import CenteredLayout from "../components/CenteredLayout/CenteredLayout"
import notFoundImage from "../images/open-peeps.png"
import SEO from "../components/SEO/SEO"

// Styles
import "../styles/app.scss"

const NotFoundPage = () => (
  <CenteredLayout>
    <SEO title="404: Not Found" />
    <Title className="title is-1 introTitle" id="main">
      This page was not found...
    </Title>
    <img src={notFoundImage} width="200px" alt="old man sitting listening" />
    <Title tag="h2" className="title is-2 titleAbout">
      404... Want to talk about it?
    </Title>{" "}
    <p>
      Pleas do{" "}
      <a href="mailto:anne@globaluniversaldesign.eu">contact us by email</a> or
      <Link to="contactUs"> through our form </Link> if you clicked a link that
      led you here. Maybe it there's a typo. Please check the URL for mistakes
      and try again.{" "}
      <span role="img" aria-label="wink">
        😉
      </span>
    </p>
    <Link to="/">{/* // TODO add back icon */}</Link>
  </CenteredLayout>
)

export default NotFoundPage
